import React, { useEffect, useState, useRef } from 'react';
import { View, Text, Pressable, Modal, ScrollView } from 'react-native';
import moment from 'moment';
import { useSync } from '../../Providers/SyncProvider';
import { ObjectId } from 'bson';
import FormHeader from './FormHeader';
import DynamicPage from './DynamicPage';
import DynamicTextArea from '../DynamicControls/DynamicTextArea';
import PageControlFooter from './PageControlFooter';
import LoadingSpinner from '../Shared/LoadingSpinner';
import Icon from '../Shared/Icon';
import { METADATA_KEYS } from '../../Constants/AppConstants';
import { LOCK_STATUS } from '../../Constants/AppConstants';
import { Formats } from '../../Constants/Formats';
import {
  Submission,
  Submission_answer,
} from '../../Models/RealmModels/Submission';
import { TemplateVersion } from '../../Models/RealmModels/TemplateVersion';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { SubmissionAnswerDTO } from '../../Types/DtoTypes';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import Colors from '../../Styles/Shared/Colors';

type CompletionFormProps = {
  navigation: any;
  submissionId: ObjectId | null;
  title?: string;
};

const CompletionForm = (props: CompletionFormProps): React.ReactElement => {
  //Leave dynamic form if props aren't populated
  if (!props.submissionId) {
    console.error('Invalid dynamic form props');
    props.navigation.pop();
  }

  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [currentPage, setPage] = useState(0);
  const [form, setForm] = useState<TemplateVersion | null>(null);
  const [formStatus, setFormStatus] = useState('');
  const [statusBackColor, setStatusBackColor] = useState('');
  const [statusFontColor, setStatusFontColor] = useState('');
  const [formCreator, setFormCreator] = useState('');
  const [formActions, setFormActions] = useState(false);
  const [submission, setSubmission] = useState<Submission | null>(null);
  const [answers, setAnswers] = useState<SubmissionAnswerDTO[]>([]);
  const [isFormCreator, setIsFormCreator] = useState(false);
  const [showDismissModal, setShowDismissModal] = useState(false);
  const [showDismissErrors, setShowDismissErrors] = useState(false);
  const [dismissReason, setDismissReason] = useState('');
  const [formUpdatedBy, setFormUpdatedBy] = useState('');
  const [icon, setIcon] = useState('');
  const [disabled, setDisabled] = useState(false);

  const {
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getSubmissions,
    getSubmissionStatuses,
    getPeople,
    getSQLUsers,
    getRealmApp,
    upsertSubmission,
  } = useSync();

  const mounted = useRef(false);

  useEffect(() => {
    setIsLoading(true);

    const getForm = async () => {
      let submission = (await getSubmissions()).find(x =>
        x._id.equals(props.submissionId!),
      );
      let form = (await getFilteredTemplateVersions()).find(x =>
        x._id.equals(submission?.templateVersion!._id!),
      )!;
      setForm(form);
      setSubmission(submission!);
      setDisabled(
        submission!.submissionStatusLock == LOCK_STATUS.LOCK ||
          submission!.submissionStatusLock == LOCK_STATUS.SUPERLOCK ||
          !isFormCreator,
      );

      let template = (await getFilteredTemplates()).find(x =>
        x._id.equals(form?.templateId),
      )!;
      if (template && template.iconSvg)
        setIcon(
          template.iconSvg
            // First: For dark mode icons (Eg. Site Inspections in home) replace white with darkestGreen
            .replaceAll(Colors.white, Colors.darkestGreen)
            //Second: For all other icons (Eg. Site Inspections in operations) replace green/currentColor with white
            .replaceAll(Colors.green, Colors.white)
            .replaceAll('currentColor', Colors.white),
        );

      if (!mounted.current) mounted.current = true;

      //Store answers so its not querying realm every time
      let answers: SubmissionAnswerDTO[] = submission!.answers.map(x => ({
        controlId: x.controlId,
        controlTypeId: x.controlTypeId,
        answer: x.answer,
      }));
      setAnswers(answers);
    };

    getForm();

    const getAttachmentFunc = async () => {
      //await getAttachments(submission?.SQLServerId!); TODO:later
    };
    getAttachmentFunc();
  }, [props.submissionId, formStatus]);

  useEffect(() => {
    const getFormInfo = async () => {
      if (submission?.createdBy) {
        let createdBy = (await getSQLUsers()).find(
          p => p.email === submission?.createdBy,
        );

        if (createdBy)
          setFormCreator(createdBy.firstName + ' ' + createdBy.lastName);

        let currentUser = getRealmApp().currentUser;
        if (
          currentUser &&
          currentUser.profile &&
          currentUser.profile.email === submission?.createdBy
        )
          setIsFormCreator(true);
      }

      if (submission?.updatedBy) {
        let updatedUser = (await getSQLUsers()).find(
          p => p.email === submission?.updatedBy,
        );

        if (updatedUser)
          setFormUpdatedBy(updatedUser.firstName + ' ' + updatedUser.lastName);
      }

      let meta = undefined;

      let dueDate = moment().toDate();
      if (submission?.metadataJSON) {
        meta = JSON.parse(submission.metadataJSON);
        let datePart = meta[METADATA_KEYS.DUEDATE];
        let timePart = meta[METADATA_KEYS.DUETIME];
        if (datePart && timePart) {
          let time = moment(timePart, Formats.BACKEND_TIME);
          let duedate = moment(datePart, Formats.BACKEND_DATE)
            .add(time.hours(), 'hours')
            .add(time.minutes(), 'minutes')
            .toDate();
          dueDate = duedate;
        }
      }

      let actions = false;
      let backColor = '';
      let fontColor = Colors.white;
      if (submission?.submissionStatus.name === SubmissionStatuses.COMPLETE) {
        setFormStatus('Complete');
        backColor = Colors.teal;
      } else if (
        submission?.submissionStatus.name === SubmissionStatuses.DISMISSED
      ) {
        let reason = '';
        if (meta) reason = meta[METADATA_KEYS.DISMISS_REASON];
        setDismissReason(reason);
        setFormStatus('Dismissed');
        backColor = Colors.gray;
      } else if (
        submission?.submissionStatus.name === SubmissionStatuses.SUBMITTED &&
        dueDate < moment().toDate()
      ) {
        setFormStatus('Overdue');
        backColor = Colors.red;
        actions = true;
      } else {
        setFormStatus('In Progress');
        backColor = Colors.white;
        fontColor = Colors.darkGreen;
        actions = true;
      }

      setStatusBackColor(backColor);
      setStatusFontColor(fontColor);
      setFormActions(actions);
    };

    getFormInfo().finally(() => {
      setIsLoading(false);
    });
  }, [submission]);

  const onStatusLockChange = (newSubmissionStatusLock: string) => {
    setDisabled(
      newSubmissionStatusLock == LOCK_STATUS.LOCK ||
        newSubmissionStatusLock == LOCK_STATUS.SUPERLOCK ||
        !isFormCreator,
    );
  };

  const onChange = async (
    controlId: number | undefined,
    controlTypeId: number | undefined,
    value: string,
    isValid: boolean,
  ) => {
    if (controlId && controlTypeId && isValid) {
      const answer = submission!.answers.find(x => x.controlId == controlId);

      if ((!answer && !value) || (answer && answer.answer === value)) return;

      if (!answer) {
        submission!.answers.push({
          controlId: controlId,
          controlTypeId: controlTypeId,
          answer: value,
        } as Submission_answer);
      } else {
        answer.answer = value;
      }

      await upsertSubmission(submission!);

      const localAnswer = answers.find(x => x.controlId == controlId);
      if (localAnswer) {
        localAnswer.answer = value;
      } else {
        const newAnswer: SubmissionAnswerDTO = {
          controlId: controlId,
          answer: value,
        };
        answers.push(newAnswer);
      }

      setAnswers(JSON.parse(JSON.stringify(answers)));
    }
  };

  const updateMetaData = async (key: string, value: string) => {
    let metadata = null;

    if (submission?.metadataJSON)
      metadata = JSON.parse(submission?.metadataJSON);

    if (metadata === null) metadata = {};

    metadata[key] = value;

    let metaDataString = JSON.stringify(metadata);
    submission!.metadataJSON = metaDataString;
    await upsertSubmission(submission!);
  };

  const goToPage = (pageNumber: number) => {
    if (
      pageNumber != currentPage &&
      pageNumber >= 0 &&
      pageNumber < form?.pages.length!
    )
      setPage(pageNumber);
  };

  const getPreviousPage = () => {
    if (currentPage > 0) setPage(currentPage - 1);
  };

  const getNextPage = () => {
    if (currentPage != form?.pages.length! - 1) setPage(currentPage + 1);
  };

  const onClose = () => {
    props.navigation.pop();
  };

  async function complete(): Promise<void> {
    setIsLoading(true);

    const submittedStatus = (await getSubmissionStatuses()).find(
      x => x.name == SubmissionStatuses.COMPLETE,
    );

    submission!.submissionStatus._id = submittedStatus?._id;
    submission!.submissionStatus.name = submittedStatus?.name;
    await upsertSubmission(submission!);

    setFormStatus('Complete');
    setIsLoading(false);
  }

  async function dismiss(): Promise<void> {
    if (!dismissReason) {
      setShowDismissErrors(true);
      return;
    }

    setIsLoading(true);

    const submittedStatus = (await getSubmissionStatuses()).find(
      x => x.name == SubmissionStatuses.DISMISSED,
    );

    submission!.submissionStatus._id = submittedStatus?._id;
    submission!.submissionStatus.name = submittedStatus?.name;

    let currentUser = getRealmApp().currentUser;
    submission!.updatedBy = currentUser!.profile.email!;

    let metadata = null;
    if (submission?.metadataJSON)
      metadata = JSON.parse(submission?.metadataJSON);

    if (metadata === null) metadata = {};

    metadata[METADATA_KEYS.DISMISS_REASON] = dismissReason;

    let metaDataString = JSON.stringify(metadata);
    submission!.metadataJSON = metaDataString;

    await upsertSubmission(submission!);

    setFormStatus('Dismissed');
    setFormUpdatedBy(currentUser!.profile.email!);
    setIsLoading(false);
    setShowDismissModal(false);
  }

  const showDismissConfirmation = () => {
    setDismissReason('');
    setShowDismissErrors(false);
    setShowDismissModal(true);
  };

  //If form is not loaded yet show nothing
  if (!form) return <></>;

  let page = form.pages[currentPage];

  let updatedLabel = '';
  if (submission) {
    if (submission?.submissionStatus.name === SubmissionStatuses.COMPLETE)
      updatedLabel = 'Completed By:';
    else if (submission?.submissionStatus.name === SubmissionStatuses.DISMISSED)
      updatedLabel = 'Dismissed By:';
  }

  const renderDismissModal = (): React.ReactElement => {
    return (
      <Modal
        visible={showDismissModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View
            style={[ControlsStyleSheet.groupSelectorModal, { height: 'auto' }]}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Dismiss Task
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setShowDismissModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView style={{ marginVertical: 20 }}>
              <Text
                style={[
                  ControlsStyleSheet.groupSelectorGroupTitle,
                  { textTransform: 'none', marginBottom: 24 },
                ]}>
                Are you sure you want to dismiss this task?
              </Text>
              <DynamicTextArea
                label="Reason"
                value={dismissReason}
                config={{ required: true, maxLength: 100 }}
                onChange={(controlId, controlTypeId, value) =>
                  setDismissReason(value)
                }
                showError={showDismissErrors}
              />
            </ScrollView>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.whiteButton,
                  { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                  },
                ]}
                onPress={() => setShowDismissModal(false)}>
                <Text style={CommonStyleSheet.whiteButtonText}>Cancel</Text>
              </Pressable>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.greenButton,
                  { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={dismiss}>
                <Text style={CommonStyleSheet.greenButtonText}>Confirm</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View style={DynamicPageStyleSheet.bodyContainer}>
      <FormHeader
        submissionId={submission?._id?.toHexString() ?? ''}
        submissionStatus={submission?.submissionStatus.name}
        submissionType="submission"
        formName={props.title ?? form.displayName ?? form.name}
        formIcon={icon}
        pageCount={form.pages.length}
        currentPage={currentPage}
        creator={formCreator}
        creatorLabel={'Created By:'}
        updatedBy={formUpdatedBy}
        updatedLabel={updatedLabel}
        pagePressed={goToPage}
        closePressed={onClose}
        validations={[]}
        showErrors={[]}
        showMoreActions={true}
        onStatusLockChange={onStatusLockChange}
        taskStatus={formStatus}
        dismissReason={dismissReason}
      />
      <View style={{ flexDirection: 'row', paddingHorizontal: 20 }}>
        <View
          style={{
            backgroundColor: statusBackColor,
            borderRadius: 6,
            paddingVertical: 4,
            marginTop: -16,
            marginBottom: 12,
          }}>
          <Text
            style={[
              DynamicPageStyleSheet.formInfo,
              { color: statusFontColor, paddingHorizontal: 12 },
            ]}>
            Status: {formStatus} {dismissReason ? `(${dismissReason})` : ''}
          </Text>
        </View>
      </View>
      {mounted.current ? (
        <DynamicPage
          navigation={props.navigation}
          submissionId={submission?._id!}
          submissionSQLServerId={submission?.SQLServerId!}
          onChange={onChange}
          updateMetaData={updateMetaData}
          submitForm={() => function () {}}
          answers={answers}
          page={page}
          pageNumber={currentPage}
          setIsLoading={(loading: boolean) => {
            setPageLoading(loading);
          }}
          disabled={disabled}
          showSubmitButton={false}
          validations={[]}
          showErrors={false}
        />
      ) : null}
      <PageControlFooter
        backOnPress={getPreviousPage}
        nextOnPress={getNextPage}
        currentPage={currentPage}
        maxPages={form.pages.length}
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: Colors.white,
          paddingHorizontal: 20,
          display: formActions ? 'flex' : 'none',
        }}>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.whiteButton,
            { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
            pressed && {
              opacity: 0.8,
            },
          ]}
          onPress={showDismissConfirmation}>
          <Text style={CommonStyleSheet.whiteButtonText}>Dismiss</Text>
        </Pressable>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.greenButton,
            { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
            pressed && {
              opacity: 0.8,
            },
          ]}
          onPress={() => complete()}>
          <Text style={CommonStyleSheet.greenButtonText}>Complete</Text>
        </Pressable>
      </View>
      <LoadingSpinner
        message="Loading"
        visible={isLoading || pageLoading || !mounted.current}
      />
      {renderDismissModal()}
    </View>
  );
};

export default CompletionForm;
