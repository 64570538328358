import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { IApiService } from './Interfaces/IApiService';
import { IAuthenticationService } from './Interfaces/IAuthenticationService';
import AuthenticationService from './AuthenticationService';
import { STORAGE_KEYS } from '../Constants/AppConstants';
import { TokenPair } from '../Types/DtoTypes';
import LocalStorageService from './LocalStorageService';
import { ILocalStorageService } from './Interfaces/ILocalStorageService';
import moment from 'moment';

class ApiService implements IApiService {
  axiosInstance: AxiosInstance;
  authenticationService: IAuthenticationService;
  storageService: ILocalStorageService;
  realmSignout: () => void;

  constructor(realmSignout: () => void) {
    this.authenticationService = new AuthenticationService();
    this.realmSignout = realmSignout;

    this.storageService = new LocalStorageService();
    this.axiosInstance = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.axiosInstance.interceptors.request.use(
      config => {
        let token = this.storageService.get(STORAGE_KEYS.TOKEN_KEY);
        let tokenExpiration = this.storageService.get(
          STORAGE_KEYS.TOKEN_EXPIRATION_KEY,
        );
        let expiration = moment(tokenExpiration);

        if (expiration < moment()) {
          let refreshToken = this.storageService.get(
            STORAGE_KEYS.REFRESH_TOKEN_KEY,
          );

          return this.authenticationService
            .refresh(refreshToken!)
            .then((response: TokenPair) => {
              config.headers['Authorization'] = 'Bearer ' + response.token;
              return config;
            })
            .catch((err: any) => {
              //Refresh failed go back to login page
              this.realmSignout();
              return config;
            });
        } else if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
          return config;
        } else return config;
      },
      error => {
        return Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.response.use(
      response => {
        return Promise.resolve(response);
      },
      error => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
          let refreshToken = this.storageService.get(
            STORAGE_KEYS.REFRESH_TOKEN_KEY,
          );

          if (refreshToken) {
            return this.authenticationService
              .refresh(refreshToken)
              .then(async (tokenPair: TokenPair) => {
                this.storageService.set(
                  STORAGE_KEYS.TOKEN_KEY,
                  tokenPair.token,
                );
                this.storageService.set(
                  STORAGE_KEYS.REFRESH_TOKEN_KEY,
                  tokenPair.refreshToken,
                );

                return this.axiosInstance.request(error.config);
              })
              .catch((err: any) => {
                //Refresh failed go back to login page
                console.log('refresh fail signout');
                this.realmSignout();
              });
          } else this.realmSignout();
        } else if (status === 403) {
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      },
    );
  }

  sendRequest<ReturnType>(
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse<ReturnType>> {
    return this.axiosInstance.request<ReturnType>(config);
  }
}

export default ApiService;
