import React, { useState, useEffect, useRef } from 'react';
import { ScrollView, View, Text, Pressable } from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { usePermission } from '../../Providers/PermissionProvider';
import { useToast } from 'react-native-toast-notifications';
import DynamicTextBox from '../DynamicControls/DynamicTextBox';
import DynamicFacepile from '../DynamicControls/DynamicFacepile';
import DynamicAutocompleteExtended from '../DynamicControls/DynamicAutocompleteExtended';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { Organisation } from '../../Models/RealmModels/Organisation';
import { PERMISSIONS } from '../../Constants/AppConstants';
import Colors from '../../Styles/Shared/Colors';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import DynamicCheckBox from '../DynamicControls/DynamicCheckBox';
import DynamicTagItems from '../DynamicControls/DynamicTagItems';
import { Navigation } from '../../Constants/Navigation';
import DynamicLabel from '../DynamicControls/DynamicLabel';

export declare type CompanyProfileProps = {
  navigation: any;
};

const CompanyProfile = (props: CompanyProfileProps): React.ReactElement => {
  const [company, setCompany] = useState<Organisation | undefined>(undefined);

  const [disabled, setDisabled] = useState(true);
  const [showErrors, setShowErrors] = useState(false);

  const { hasPermission } = usePermission();
  const { getOrgId, getOrganisations, upsertOrganisations } = useSync();
  const toast = useToast();

  useEffect(() => {
    let permission = hasPermission(PERMISSIONS.COMPANY_PROFILE_UPDATE);

    setDisabled(!permission);
  }, []);

  useEffect(() => {
    const loadCompany = async () => {
      let orgId = getOrgId();
      let realmOrg = (await getOrganisations()).find(
        o => o.SQLServerId === orgId,
      );

      if (!realmOrg) {
        props.navigation.pop();
        return;
      }

      setCompany(realmOrg);
    };

    loadCompany();
  }, []);

  const onChange = (propName: string, propValue: string | null) => {
    if (!company) return;

    if (propName === 'name') {
      company.name = propValue ?? '';
    } else if (propName === 'tradeName') {
      company.tradeName = propValue ?? '';
    } else if (propName === 'owners') {
      company.owners = propValue ?? '';
    } else if (propName === 'mailingLocationJSON') {
      company.mailingLocationJSON = propValue ?? '';
    } else if (propName === 'headOfficeLocationJSON') {
      company.headOfficeLocationJSON = propValue ?? '';
    } else if (propName === 'primaryContactJSON') {
      company.primaryContactJSON = propValue ?? '';
    } else if (propName === 'safetyContactJSON') {
      company.safetyContactJSON = propValue ?? '';
    } else if (propName === 'auditContactJSON') {
      company.auditContactJSON = propValue ?? '';
    } else if (propName === 'WSBCNumber') {
      company.WSBCNumber = propValue ?? '';
    } else if (propName === 'SAFENumber') {
      company.SAFENumber = propValue ?? '';
    } else if (propName === 'CORNumber') {
      company.CORNumber = propValue ?? '';
    } else if (propName === 'WSBCUnitsJSON') {
      company.WSBCUnitsJSON = propValue ?? '';
    } else if (propName === 'mainActivities') {
      company.mainActivities = propValue ?? '';
    }

    let newCompany = { ...company } as Organisation;
    setCompany(newCompany);
  };

  const save = async () => {
    if (!company) return;

    if (
      !company.name ||
      !company.tradeName ||
      !company.primaryContactJSON ||
      !company.headOfficeLocationJSON
    ) {
      setShowErrors(true);
      return;
    }

    company.dataHubVersion = Math.round(Date.now() / 1000);

    await upsertOrganisations(company);

    toast.show('Company Profile saved successfully', { type: 'success' });

    props.navigation.push(Navigation.HOME);
  };

  return (
    <ScrollView>
      <View
        style={{
          height: 260,
          backgroundColor: Colors.darkestGreen,
          paddingHorizontal: 24,
        }}>
        <Text style={ReportsStyleSheet.headerTitle}>Company Profile</Text>
      </View>
      <View style={{ padding: 20, backgroundColor: Colors.white }}>
        {company ? (
          <ScrollView keyboardShouldPersistTaps="handled">
            <DynamicLabel
              config={{
                fontSize: 28,
                fontFamily: 'Poppins',
                color: Colors.darkestGreen,
                fontWeight: '700',
              }}
              value="Company Information"
            />
            <View style={CommonStyleSheet.grid}>
              <View style={CommonStyleSheet.column}>
                <DynamicTextBox
                  config={{
                    required: true,
                  }}
                  label="Legal Company Name"
                  value={company.name}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('name', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
              <View style={CommonStyleSheet.column}>
                <DynamicTextBox
                  config={{
                    required: true,
                  }}
                  label="Company Trade Name / DBA"
                  value={company.tradeName}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('tradeName', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
            </View>
            <View style={CommonStyleSheet.grid}>
              <View style={CommonStyleSheet.column}>
                <DynamicFacepile
                  label="Company Owner(s)"
                  config={{
                    optionSource: ListDataTypes.PEOPLE,
                  }}
                  value={company.owners ?? ''}
                  onChange={(controlId, controlTypeId, value, valid) => {
                    onChange('owners', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
            </View>
            <DynamicLabel
              config={{
                fontSize: 28,
                fontFamily: 'Poppins',
                color: Colors.darkestGreen,
                fontWeight: '700',
              }}
              value="WSBC Information"
            />
            <View style={CommonStyleSheet.grid}>
              <View style={CommonStyleSheet.column}>
                <DynamicTextBox
                  label="WSBC account number"
                  value={company.WSBCNumber}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('WSBCNumber', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
              <View style={CommonStyleSheet.column}>
                <DynamicTagItems
                  config={{ optionSource: ListDataTypes.CLASSIFICATIONUNITS }}
                  label="List all the company's WSBC Classification Unit(s)"
                  value={company.WSBCUnitsJSON}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('WSBCUnitsJSON', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
            </View>
            <View style={CommonStyleSheet.grid}>
              <View style={CommonStyleSheet.column}>
                <DynamicTextBox
                  label="What does your company do as its main activities?"
                  value={company.mainActivities}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('mainActivities', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
            </View>
            <View style={[CommonStyleSheet.grid, { marginBottom: 24 }]}>
              <View style={CommonStyleSheet.column}>
                <DynamicLabel
                  config={{
                    fontSize: 28,
                    fontFamily: 'Poppins',
                    color: Colors.darkestGreen,
                    fontWeight: '700',
                  }}
                  value="Mailing Address"
                />
                <DynamicAutocompleteExtended
                  navigation={props.navigation}
                  label=""
                  config={{
                    optionSource: ListDataTypes.PROJECTS,
                    dataFields: [
                      { fieldName: 'citySQLServerId', label: 'City' },
                      { fieldName: 'cityName', label: 'City' },
                      { fieldName: 'address', label: 'Address' },
                      { fieldName: 'provStateSQLServerId', label: 'Province' },
                      { fieldName: 'postalZip', label: 'Postal Code' },
                    ],
                  }}
                  value={company.mailingLocationJSON}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('mailingLocationJSON', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
              <View style={CommonStyleSheet.column}>
                <DynamicLabel
                  config={{
                    fontSize: 28,
                    fontFamily: 'Poppins',
                    color: Colors.darkestGreen,
                    fontWeight: '700',
                    requiredFlag: true,
                  }}
                  value="Street Address"
                />
                <DynamicAutocompleteExtended
                  navigation={props.navigation}
                  label=""
                  config={{
                    optionSource: ListDataTypes.PROJECTS,
                    dataFields: [
                      { fieldName: 'citySQLServerId', label: 'City' },
                      { fieldName: 'cityName', label: 'City' },
                      { fieldName: 'address', label: 'Address' },
                      { fieldName: 'provStateSQLServerId', label: 'Province' },
                      { fieldName: 'postalZip', label: 'Postal Code' },
                    ],
                    required: true,
                  }}
                  value={company.headOfficeLocationJSON}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('headOfficeLocationJSON', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
            </View>
            <View style={[CommonStyleSheet.grid, { marginBottom: 24 }]}>
              <View style={CommonStyleSheet.column}>
                <DynamicLabel
                  config={{
                    fontSize: 28,
                    fontFamily: 'Poppins',
                    color: Colors.darkestGreen,
                    fontWeight: '700',
                    requiredFlag: true,
                  }}
                  value="Primary Contact"
                />
                <DynamicAutocompleteExtended
                  navigation={props.navigation}
                  label=""
                  config={{
                    optionSource: ListDataTypes.PEOPLE,
                    dataFields: [
                      { fieldName: 'jobSQLServerId', label: 'Job Title' },
                      { fieldName: 'email', label: 'Email' },
                      { fieldName: 'phone', label: 'Cell Phone' },
                      { fieldName: 'phoneDirect', label: 'Office Telephone' },
                      { fieldName: 'fax', label: 'Fax' },
                    ],
                    required: true,
                  }}
                  value={company.primaryContactJSON}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('primaryContactJSON', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
              <View style={CommonStyleSheet.column}>
                <DynamicLabel
                  config={{
                    fontSize: 28,
                    fontFamily: 'Poppins',
                    color: Colors.darkestGreen,
                    fontWeight: '700',
                  }}
                  value="Safety Contact"
                />
                <DynamicAutocompleteExtended
                  navigation={props.navigation}
                  label=""
                  config={{
                    optionSource: ListDataTypes.PEOPLE,
                    dataFields: [
                      { fieldName: 'jobSQLServerId', label: 'Job Title' },
                      { fieldName: 'email', label: 'Email' },
                      { fieldName: 'phone', label: 'Cell Phone' },
                      { fieldName: 'phoneDirect', label: 'Office Telephone' },
                      { fieldName: 'fax', label: 'Fax' },
                    ],
                  }}
                  value={company.safetyContactJSON}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('safetyContactJSON', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
            </View>
            <View style={[CommonStyleSheet.grid, { marginBottom: 24 }]}>
              <View style={CommonStyleSheet.column}>
                <DynamicLabel
                  config={{
                    fontSize: 28,
                    fontFamily: 'Poppins',
                    color: Colors.darkestGreen,
                    fontWeight: '700',
                  }}
                  value="Audit Contact"
                />
                <DynamicAutocompleteExtended
                  navigation={props.navigation}
                  label=""
                  config={{
                    optionSource: ListDataTypes.PEOPLE,
                    dataFields: [
                      { fieldName: 'jobSQLServerId', label: 'Job Title' },
                      { fieldName: 'email', label: 'Email' },
                      { fieldName: 'phone', label: 'Cell Phone' },
                      { fieldName: 'phoneDirect', label: 'Office Telephone' },
                      { fieldName: 'fax', label: 'Fax' },
                    ],
                  }}
                  value={company.auditContactJSON}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('auditContactJSON', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
            </View>
            <DynamicLabel
              config={{
                fontSize: 28,
                fontFamily: 'Poppins',
                color: Colors.darkestGreen,
                fontWeight: '700',
              }}
              value="Additional Information"
            />
            <View style={CommonStyleSheet.grid}>
              <View style={CommonStyleSheet.column}>
                <DynamicTextBox
                  label="SAFE Certification Number"
                  value={company.SAFENumber}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('SAFENumber', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
              <View style={CommonStyleSheet.column}>
                <DynamicTextBox
                  label="COR Certification Number"
                  value={company.CORNumber}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('CORNumber', value);
                  }}
                  disabled={disabled}
                  showError={showErrors}
                />
              </View>
            </View>
            <View>
              <Pressable
                style={[
                  CommonStyleSheet.greenButton,
                  disabled && { opacity: 0.4 },
                ]}
                android_ripple={{ color: Colors.whiteTransparent }}
                onPress={save}
                disabled={disabled}>
                <Text style={CommonStyleSheet.greenButtonText}>Save</Text>
              </Pressable>
            </View>
          </ScrollView>
        ) : (
          <LoadingSpinner message="Loading" visible={true} />
        )}
      </View>
    </ScrollView>
  );
};

export default CompanyProfile;
