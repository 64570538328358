import React, { useEffect, useState, useRef } from 'react';
import { View, Image, Text, TextInput, Pressable, Linking } from 'react-native';
import AppleSignin from 'react-apple-signin-auth';
import { GoogleLogin } from '@react-oauth/google';
import { IAuthenticationService } from '../../Services/Interfaces/IAuthenticationService';
import AuthenticationService from '../../Services/AuthenticationService';
import { TokenPair } from '../../Types/DtoTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import Icon from '../Shared/Icon';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { LoginStyleSheet } from '../../Styles/LoginStyles';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { useCustomRealm } from '../../Providers/CustomRealmProvider';
import { typographyClasses } from '@mui/material';
import { useSync } from '../../Providers/SyncProvider';
import { ILogService } from '../../Services/Interfaces/ILogService';
import LogService from '../../Services/LogService';

type LoginProps = {};

const Login = (props: LoginProps): React.ReactElement => {
  const authService: IAuthenticationService = new AuthenticationService();
  const { realmSignIn, isLoggedIn } = useCustomRealm();
  const { loadRealmData } = useSync();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordResult, setForgotPasswordResult] = useState<
    boolean | null
  >(null);
  const [forgottenEmail, setForgottenEmail] = useState('');
  const [forgottenEmailError, setForgottenEmailError] = useState('');

  const emailRef = useRef<TextInput>(null);
  const passwordRef = useRef<TextInput>(null);
  const [passwordSelection, setPasswordSelection] = useState<{ start: number }>(
    { start: 0 },
  );

  const logService: ILogService = new LogService();

  useEffect(() => {
    if (emailRef.current) emailRef.current.focus();
  }, []);

  useEffect(() => {
    if (password && passwordRef.current) passwordRef.current.focus();
  }, [passwordSelection]);

  function emailValidation(): boolean {
    setEmailError('');

    if (email === '') {
      setEmailError('Email is required');
      return false;
    } else if (!isValidEmail(email)) {
      setEmailError('Invalid email');
      return false;
    }

    return true;
  }

  function passwordValidation(): boolean {
    setPasswordError('');

    if (password === '') {
      setPasswordError('Password is required');
      return false;
    }

    return true;
  }

  function forgottenEmailValidation(): boolean {
    setForgottenEmailError('');

    if (forgottenEmail === '') {
      setForgottenEmailError('Email is required');
      return false;
    } else if (!isValidEmail(forgottenEmail)) {
      setForgottenEmailError('Invalid email');
      return false;
    }

    return true;
  }

  function isValidEmail(email: string): boolean {
    const emailRegExp = new RegExp(
      '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
    );

    return emailRegExp.test(email) === true;
  }

  async function login(): Promise<void> {
    let emailValid = emailValidation();
    let passwordValid = passwordValidation();
    if (emailValid && passwordValid) {
      setLoginError('');
      setIsLoading(true);
      await signIn();
      await logService.sendLog('Login');
    }
  }

  const onLoginSuccess = async (token: string) => {
    await realmSignIn(token);
    await loadRealmData();
    isLoggedIn();
  };

  const googleLogin = async (googleCred: string) => {
    setIsLoading(true);
    try {
      let tokenPair = await authService.googleSignIn(googleCred);
      await onLoginSuccess(tokenPair.token);

      await logService.sendLog('Login');
    } catch (err) {
      //doSomething
      let errMsg = getErrorMessage(err);

      if (errMsg) setLoginError(errMsg);
      else setLoginError('Login failed');
    }
    setIsLoading(false);
  };

  const appleLogin = async (appleToken: string) => {
    setIsLoading(true);
    try {
      let tokenPair = await authService.appleSignIn(appleToken);
      await onLoginSuccess(tokenPair.token);
      await logService.sendLog('Login');
    } catch (err) {
      //doSomething
      console.log(err);
      let errMsg = getErrorMessage(err);

      if (errMsg) setLoginError(errMsg);
      else setLoginError('Login failed');
    }
    setIsLoading(false);
  };

  const signIn = async () => {
    try {
      let tokenPair = await authService.login(email, password);
      await onLoginSuccess(tokenPair.token);
    } catch (error) {
      let errMsg = getErrorMessage(error);

      if (errMsg) setLoginError(errMsg);
      else setLoginError('Login failed');
    } finally {
      setIsLoading(false);
    }
  };

  async function sendReset(): Promise<void> {
    if (forgottenEmailValidation()) {
      setIsLoading(true);

      authService
        .forgotPassword(forgottenEmail)
        .then((status: number) => {
          setForgotPasswordResult(status === 200);
        })
        .catch(error => {
          setForgotPasswordResult(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function getErrorMessage(error: any): string | null {
    if (error.response && error.response.data) {
      if (
        error.response.data == 'invalid_username_or_password' ||
        error.response.data.error_description == 'invalid_username_or_password'
      )
        return 'Login failed.';
      else if (
        error.response.data == 'locked_out' ||
        error.response.data.error_description == 'locked_out'
      )
        return 'Your account is locked out. Please try again later or have an admin unlock your account.';
      else if (
        error.response.data == 'invalid_subscription' ||
        error.response.data.error_description == 'invalid_subscription'
      )
        return 'Your subscription is invalid. Please contact support.';
    }

    return null;
  }

  const goToRegister = () => {
    const link = process.env.REACT_APP_REGISTER_URL;
    if (link) Linking.openURL(link);
  };

  const goToForgotPassword = () => {
    setForgotPassword(true);
    setForgotPasswordResult(null);
  };

  const showPassword = () => {
    setPasswordVisible(!passwordVisible);
    setPasswordSelection({ start: password.length });
  };

  const renderLogin = (): React.ReactElement => {
    const AUTH_STATE = (Math.random() * Number.MAX_SAFE_INTEGER).toString();
    const NONCE = (Math.random() * Number.MAX_SAFE_INTEGER).toString();

    return (
      <View style={{ width: 360, alignSelf: 'center' }}>
        <Text style={LoginStyleSheet.label}>Email</Text>
        <TextInput
          ref={emailRef}
          style={LoginStyleSheet.input}
          keyboardType="email-address"
          value={email}
          onChangeText={newText => setEmail(newText)}
        />
        {emailError ? (
          <Text style={CommonStyleSheet.inputError}>{emailError}</Text>
        ) : null}
        <Text style={LoginStyleSheet.label}>Password</Text>
        <View>
          <TextInput
            ref={passwordRef}
            selection={passwordSelection}
            style={LoginStyleSheet.input}
            secureTextEntry={!passwordVisible}
            value={password}
            onChangeText={newText => setPassword(newText)}
            onBlur={passwordValidation}
          />
          <Pressable
            style={({ pressed }) => [
              LoginStyleSheet.showPasswordButton,
              pressed && {
                backgroundColor: Colors.darkGreenTransparent,
              },
            ]}
            onPress={() => showPassword()}>
            {passwordVisible ? (
              <Icon icon={'eye'} color={Colors.green} size={24} />
            ) : (
              <Icon icon={'eye-slash'} color={Colors.green} size={24} />
            )}
          </Pressable>
        </View>
        {passwordError ? (
          <Text style={CommonStyleSheet.inputError}>{passwordError}</Text>
        ) : null}
        <Pressable
          style={({ pressed }) => [
            LoginStyleSheet.linkButton,
            { alignSelf: 'flex-end' },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={goToForgotPassword}>
          <Text style={LoginStyleSheet.forgotPassword}>Forgot Password?</Text>
        </Pressable>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.greenButton,
            LoginStyleSheet.button,
            { width: 260 },
            pressed && {
              opacity: 0.8,
            },
          ]}
          onPress={() => {
            login();
          }}>
          <Text style={CommonStyleSheet.greenButtonText}>Login</Text>
        </Pressable>
        {loginError ? (
          <Text style={[CommonStyleSheet.inputError, { alignSelf: 'center' }]}>
            {loginError}
          </Text>
        ) : null}

        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
          <View
            style={[
              CommonStyleSheet.separator,
              { alignItems: 'center', flexGrow: 1, bottom: 7 },
            ]}></View>
          <View style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Text style={{ color: 'darkgray' }}>or</Text>
          </View>
          <View
            style={[
              CommonStyleSheet.separator,
              { alignItems: 'center', flexGrow: 1, bottom: 7 },
            ]}></View>
        </View>
        <View style={{ alignItems: 'center' }}>
          <View style={LoginStyleSheet.googleButton}>
            <GoogleLogin
              width={260}
              onSuccess={credentialResponse => {
                googleLogin(credentialResponse.credential!);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </View>
          <AppleSignin
            authOptions={{
              clientId: process.env.REACT_APP_SIGN_IN_WITH_APPLE_WEB_CLIENT_ID!,
              scope: 'email',
              redirectURI:
                process.env.REACT_APP_SIGN_IN_WITH_APPLE_WEB_REDIRECT_URI!,
              state: AUTH_STATE,
              nonce: NONCE,
              usePopup: true,
            }}
            uiType="dark"
            className="apple-auth-btn"
            noDefaultStyle={false}
            buttonExtraChildren="Continue with Apple"
            onSuccess={(response: any) => {
              appleLogin(response.authorization.id_token!);
            }}
            onError={(error: any) => console.error(error)}
          />

          <Pressable
            style={({ pressed }) => [
              LoginStyleSheet.linkButton,
              { alignSelf: 'center' },
              pressed && {
                backgroundColor: Colors.darkGreenTransparent,
              },
            ]}
            onPress={() => goToRegister()}>
            <Text style={LoginStyleSheet.register}>Or Register</Text>
          </Pressable>
        </View>
      </View>
    );
  };

  const renderForgotPassword = (): React.ReactElement => {
    return (
      <View style={{ width: 360, alignSelf: 'center' }}>
        <Pressable
          style={({ pressed }) => [
            LoginStyleSheet.linkButton,
            { alignSelf: 'flex-start' },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => setForgotPassword(false)}>
          <Text style={[LoginStyleSheet.label, { marginTop: 2 }]}>Back</Text>
        </Pressable>
        <Text style={[LoginStyleSheet.resetPassword, { marginTop: 20 }]}>
          Please provide your email and a password reset link shall be emailed
          to you.
        </Text>
        <View>
          <Text style={LoginStyleSheet.label}>Email</Text>
          <TextInput
            style={LoginStyleSheet.input}
            keyboardType="email-address"
            value={forgottenEmail}
            onChangeText={newText => setForgottenEmail(newText)}
            onBlur={forgottenEmailValidation}
          />
          {forgottenEmailError ? (
            <Text style={CommonStyleSheet.inputError}>
              {forgottenEmailError}
            </Text>
          ) : null}
        </View>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.greenButton,
            LoginStyleSheet.button,
            pressed && {
              opacity: 0.8,
            },
            forgotPasswordResult !== null && {
              backgroundColor: Colors.gray,
            },
          ]}
          disabled={forgotPasswordResult !== null}
          onPress={sendReset}>
          <Text style={CommonStyleSheet.greenButtonText}>
            Send password reset email
          </Text>
        </Pressable>
        {forgotPasswordResult === true ? (
          <Text
            style={[
              LoginStyleSheet.resetPassword,
              LoginStyleSheet.resetPasswordSuccess,
            ]}>
            Reset password email sent. Please check your inbox and follow the
            link.
          </Text>
        ) : null}
        {forgotPasswordResult === false ? (
          <Text style={[CommonStyleSheet.inputError, { alignSelf: 'center' }]}>
            Reset password email failed. Please check your connection and try
            again.
          </Text>
        ) : null}
      </View>
    );
  };

  return (
    <View style={LoginStyleSheet.container}>
      <View style={LoginStyleSheet.form}>
        <Image source={Images.FIRS_LOGO_DARK} style={LoginStyleSheet.logo} />
        {forgotPassword ? renderForgotPassword() : renderLogin()}
        <Text style={LoginStyleSheet.footerText}>
          Built by EHS Analytics
          {'\n'}
          App Version {process.env.REACT_APP_VERSION_NAME}
        </Text>
        <LoadingSpinner message="Loading" visible={isLoading} />
      </View>
    </View>
  );
};

export default Login;
