import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TextInput,
  Pressable,
  Modal,
  Keyboard,
} from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { ObjectId, UUID } from 'bson';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionLink } from '../../Models/RealmModels/SubmissionLink';
import DynamicSegmentedControl from '../DynamicControls/DynamicSegmentedControl';
import { DynamicLinkRecordProps } from '../../Types/ControlTypes';
import { DynamicLinkType } from '../../Constants/General';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Icon from '../Shared/Icon';
import Colors from '../../Styles/Shared/Colors';
import { Navigation } from '../../Constants/Navigation';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import moment from 'moment';
import { Formats } from '../../Constants/Formats';
import { METADATA_KEYS, COMMON_TEMPLATES } from '../../Constants/AppConstants';
import { navigationRef, currentKey } from '../Shared/RootNavigation';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';

type Record = {
  key: number;
  id: string;
  name: string;
  status: string;
  datetime: Date;
  icon: string;
};

const DynamicLinkRecord = (
  props: DynamicLinkRecordProps,
): React.ReactElement => {
  const [value, setValue] = useState<string[]>([]);
  const [selection, setSelection] = useState<string[]>([]);
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [templateFilter, setTemplateFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [filters, setFilters] = useState<string[]>([]);
  const [configVersions, setConfigVersions] = useState<string[]>([]);
  const [records, setRecords] = useState<Record[]>([]);

  const defaultItemsToShow = 10;
  const [itemsToShow, setItemsToShow] = useState(defaultItemsToShow);

  const [myKey, setMyKey] = useState('');
  const [navKey, setNavKey] = useState('');

  const {
    getFilteredTemplateVersions,
    getSubmissions,
    upsertSubmission,
    getSubmissionStatuses,
    getSubmissionLinks,
    addSubmissionLink,
    removeSubmissionLinks,
    getFilteredTemplates,
    getOrgId,
    getRealmApp,
  } = useSync();
  const [submissionRecords, setSubmissionRecords] = useState<Submission[]>([]);

  useEffect(() => {
    if (props.navigation) {
      setMyKey(currentKey());
      setNavKey(currentKey());

      if (navigationRef.current) {
        const unsubscribe = navigationRef.current.addListener('state', () => {
          setNavKey(currentKey());
        });

        return unsubscribe;
      }
    }
  }, []);

  useEffect(() => {
    if (myKey === navKey && configVersions && configVersions.length > 0)
      fetchRecords(configVersions);
  }, [myKey, navKey]);

  useEffect(() => {
    getSubmissionLinks().then(response => {
      let linkedRecords = [];

      if (props.config?.linkType == DynamicLinkType.CHILD) {
        linkedRecords = response.filter(
          x => String(x.parentSubmissionId) == props.submissionId,
        );
      } else if (props.config?.linkType == DynamicLinkType.PARENT)
        linkedRecords = response.filter(
          x => String(x.childSubmissionId) == props.submissionId,
        );
      else {
        throw new Error('Invalid DynamicLinkConfig - invalid link type');
      }

      let newValue = linkedRecords.map(x => String(x.childSubmissionId));
      setValue(newValue);
    });

    fetchVersions();
  }, []);

  async function fetchVersions(): Promise<void> {
    let configTemplates: string[] = [];

    if (props.config?.templateId)
      configTemplates.push(props.config?.templateId);
    else if (props.config?.templateCategory) {
      let categoryId = props.config.templateCategory;

      let templates = await getFilteredTemplates();
      let filteredTemplates = templates.filter(t =>
        t.templateCategory.templateCategoryId.equals(categoryId),
      );

      if (filteredTemplates.length > 0)
        configTemplates = filteredTemplates.map(t => t._id.toHexString());

      setFilters(filteredTemplates.map(t => t.name));
    } else if (props.config?.templateType) {
      let typeId = props.config.templateType;

      let templates = await getFilteredTemplates();
      let filteredTemplates = templates.filter(t =>
        t.templateType._id.equals(typeId),
      );

      if (filteredTemplates.length > 0)
        configTemplates = filteredTemplates.map(t => t._id.toHexString());

      setFilters(filteredTemplates.map(t => t.name));
    }

    let templateVersions = await getFilteredTemplateVersions();

    let versions = templateVersions
      .filter(v => configTemplates.includes(String(v.templateId)))
      .map(v => String(v._id));

    setConfigVersions(versions);
    fetchRecords(versions);
  }

  async function fetchRecords(versions: string[]): Promise<void> {
    const newRecords: Record[] = [];

    let submissions = await getSubmissions();

    let sub = submissions.filter(
      s =>
        versions.includes(String(s.templateVersion._id)) &&
        s.submissionStatus.name === SubmissionStatuses.SUBMITTED,
    );

    let subLinks = await getSubmissionLinks();

    let filteredSubmissionLinks = subLinks;

    if (props.config?.linkType !== DynamicLinkType.PARENT)
      filteredSubmissionLinks = filteredSubmissionLinks.filter(
        x => !x.parentSubmissionId.equals(props.submissionId),
      );

    //using hash so loop is faster and isn't looping loops
    let childSubmissionLinkMap = new Map<string, SubmissionLink>();

    for (let i = 0; i < filteredSubmissionLinks.length; i++)
      childSubmissionLinkMap.set(
        filteredSubmissionLinks[i].childSubmissionId.toHexString(),
        filteredSubmissionLinks[i],
      );

    sub.forEach(function (s, i) {
      let hasAnotherParent = childSubmissionLinkMap.has(s._id.toHexString());

      if (!hasAnotherParent) {
        let title = s.templateVersion.name!;
        let date = moment(s.createDateTimeStamp).toDate();
        if (s.metadataJSON) {
          let meta = JSON.parse(s.metadataJSON);
          if (meta[METADATA_KEYS.TITLE]) title = meta[METADATA_KEYS.TITLE];
          let datePart = '';
          if (meta[METADATA_KEYS.DUEDATE])
            datePart = meta[METADATA_KEYS.DUEDATE];
          let timePart = '';
          if (meta[METADATA_KEYS.DUETIME])
            timePart = meta[METADATA_KEYS.DUETIME];
          if (datePart && timePart) {
            let time = moment(timePart, Formats.BACKEND_TIME);
            let duedate = moment(datePart, Formats.BACKEND_DATE)
              .add(time.hours(), 'hours')
              .add(time.minutes(), 'minutes')
              .toDate();
            date = duedate;
          }
        }
        newRecords.push({
          key: i,
          id: String(s._id),
          name: title,
          status: s.submissionStatus.name!,
          datetime: date,
          icon: '',
        });
      }
    });
    newRecords.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
    setRecords(newRecords);
  }

  function showModal(): void {
    if (props.disabled) return;

    Keyboard.dismiss();

    if (value) {
      setSelection(JSON.parse(JSON.stringify(value)));
    } else setSelection([]);

    setItemsToShow(defaultItemsToShow);
    setTemplateFilter('');
    setSearchFilter('');
    setIsShowingModal(true);
  }

  function hideModal(): void {
    setIsShowingModal(false);
  }

  function clearSearchFilter(): void {
    Keyboard.dismiss();
    setSearchFilter('');
  }

  async function addNew(): Promise<void> {
    let templateId: string | undefined = undefined;

    if (props.config?.templateId) templateId = props.config.templateId;
    else if (props.config?.templateType) {
      let typeId = props.config.templateType;
      let filteredTemplates = (await getFilteredTemplates()).filter(t =>
        t.templateType._id.equals(typeId),
      );

      if (filteredTemplates && filteredTemplates.length > 0)
        templateId = filteredTemplates[0]._id.toHexString();
    }

    if (templateId) {
      let templateVersions = await getFilteredTemplateVersions();

      let versions = templateVersions
        .filter(x => x.templateId.toHexString() === templateId)
        .sort(x => x.version)
        .map(x => ({ _id: x._id, name: x.name }));

      if (versions.length == 0) throw Error('No forms found!');
      let newTemplateVersion = versions[0];

      const draftStatus = (await getSubmissionStatuses()).find(
        x => x.name == 'Draft',
      )!;

      let org = '';
      let currentEmail = '';

      let currentUser = getRealmApp().currentUser;

      if (currentUser && currentUser.profile) {
        if (typeof currentUser.profile.organisation === 'string') {
          let organisation = JSON.parse(currentUser.profile.organisation);
          org = organisation.Id.toString();
        }

        if (typeof currentUser.profile.email === 'string') {
          currentEmail = currentUser.profile.email;
        }
      }

      let templates = await getFilteredTemplates();
      let newTemplate = templates.find(x => x._id.equals(templateId!));

      // Build new submission
      let newSubmission: Submission = {
        _id: new ObjectId(),
        partition: org,
        createdBy: currentEmail as string,
        updatedBy: currentEmail as string,
        templateVersion: {
          _id: newTemplateVersion._id,
          name: newTemplateVersion.name,
        },
        templateType: {
          _id: newTemplate!.templateType._id,
          name: newTemplate!.templateType.name,
        },
        submissionStatus: {
          _id: draftStatus._id,
          name: draftStatus.name,
        },
        SQLServerId: new UUID().toHexString(),
        answers: [],
        metadataJSON: '',
        createDateTimeStamp: new Date(),
        updateDateTimeStamp: new Date(),
      };

      // Create new submission
      await upsertSubmission(newSubmission);

      addNewTask(newSubmission._id);

      props.navigation.push(Navigation.DYNAMICFORM, {
        submissionId: newSubmission._id,
        showConfirmation: false,
      });
    }
  }

  function addNewTask(recordId: ObjectId): void {
    let valueArray = [];

    if (value) valueArray = JSON.parse(JSON.stringify(value));

    valueArray.push(recordId.toHexString());
    setValue(valueArray);

    let newSelection = [...selection];
    newSelection.push(recordId.toHexString());

    setSelection(newSelection);
  }

  function select(record: Record): void {
    let newSelection = [...selection];

    let index = newSelection.indexOf(String(record.id));
    if (index === -1) newSelection.push(String(record.id));
    else newSelection.splice(index, 1);

    setSelection(newSelection);
  }

  function link(): void {
    setValue(selection);
    setIsShowingModal(false);

    let orgid = getOrgId();

    //Delete existing links
    getSubmissionLinks()
      .then(response => {
        if (props.config?.linkType == DynamicLinkType.PARENT) {
          removeSubmissionLinks(new ObjectId(props.submissionId), false);
        } else if (props.config?.linkType == DynamicLinkType.CHILD) {
          removeSubmissionLinks(new ObjectId(props.submissionId), true);
        } else throw new Error('Invalid dynamic link config');
      })
      .finally(() => {
        // Add new links
        for (let i = 0; i < selection.length; i++) {
          let item = selection[i];
          let parentSubmissionId = '';
          let childSubmissionId = '';

          if (props.config?.linkType == DynamicLinkType.PARENT) {
            parentSubmissionId = item;
            childSubmissionId = props.submissionId;
          } else if (props.config?.linkType == DynamicLinkType.CHILD) {
            parentSubmissionId = props.submissionId;
            childSubmissionId = item;
          } else throw new Error('Invalid dynamic link config');

          let newSubmissionLink: SubmissionLink = {
            _id: new ObjectId(),
            parentSubmissionId: new ObjectId(parentSubmissionId),
            childSubmissionId: new ObjectId(childSubmissionId),
            partition: orgid.toString(),
            createDateTimeStamp: new Date(),
          };

          addSubmissionLink(newSubmissionLink);
        }
      });
  }

  async function goToRecord(record: Record): Promise<void> {
    let submission = (await getSubmissions()).find(x =>
      x._id.equals(record.id),
    );

    if (submission && submission.templateVersion.name === COMMON_TEMPLATES.TASK)
      props.navigation.push(Navigation.DYNAMICFORM, {
        submissionId: record.id,
        headerTitle: 'Task',
      });
    else
      props.navigation.push(Navigation.DYNAMICFORM, {
        submissionId: record.id,
      });
  }

  const renderModal = (): React.ReactElement => {
    let recordsToShow = records.slice(0, itemsToShow);

    return (
      <Modal
        visible={isShowingModal && myKey === navKey}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {props.label ?? 'Link a record'}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={hideModal}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            {renderFilters()}
            {(props.config?.templateId || props.config?.templateType) && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 12,
                }}>
                <Text style={ControlsStyleSheet.labelLarge}>Create New</Text>
                <Pressable
                  style={({ pressed }) => [
                    ControlsStyleSheet.linkRecordAddButton,
                    pressed && {
                      backgroundColor: Colors.darkGreenTransparent,
                    },
                  ]}
                  onPress={addNew}>
                  <Icon icon={'plus'} color={Colors.darkestGreen} size={24} />
                </Pressable>
              </View>
            )}
            <ScrollView style={{ marginVertical: 20 }}>
              {recordsToShow.map((record, index) => {
                return renderRecord(record, index);
              })}
              {recordsToShow.length < records.length && (
                <Pressable
                  style={({ pressed }) => [
                    CommonStyleSheet.smallGreenButton,
                    { alignSelf: 'center', marginVertical: 12 },
                    pressed && {
                      opacity: 0.8,
                    },
                  ]}
                  onPress={() =>
                    setItemsToShow(itemsToShow + defaultItemsToShow)
                  }>
                  <Text style={CommonStyleSheet.smallGreenButtonText}>
                    Show More
                  </Text>
                </Pressable>
              )}
            </ScrollView>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                pressed && {
                  opacity: 0.8,
                },
              ]}
              onPress={link}>
              <Text style={CommonStyleSheet.greenButtonText}>Ok</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  const renderFilters = (): React.ReactElement => {
    // Don't show filters if a type was configured
    if (props.config?.templateType) return <></>;

    let segments = filters.map(f => {
      return { name: f };
    });

    return (
      <View>
        <View style={{ alignItems: 'center', marginBottom: 12 }}>
          {!props.config?.templateId && filters.length > 1 && (
            <DynamicSegmentedControl
              config={{
                segments: segments,
              }}
              value={templateFilter}
              onChange={(controlId, controlTypeId, value) => {
                setTemplateFilter(value);
              }}
            />
          )}
        </View>
        <View
          style={[
            CommonStyleSheet.searchContainer,
            { paddingHorizontal: 0, marginTop: 12 },
          ]}>
          <View style={CommonStyleSheet.searchBoxContainer}>
            <View style={CommonStyleSheet.searchBoxIcon}>
              <Icon icon={'input-search'} color={Colors.darkGreen} size={24} />
            </View>
            <TextInput
              returnKeyType="search"
              style={CommonStyleSheet.searchBoxInput}
              placeholder="Search"
              placeholderTextColor={Colors.gray}
              value={searchFilter}
              onChangeText={newText => setSearchFilter(newText)}
            />
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.searchBoxClear,
                { display: searchFilter === '' ? 'none' : 'flex' },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                  borderRadius: 14,
                },
              ]}
              onPress={clearSearchFilter}>
              <Icon icon={'input-clear'} color={Colors.darkGreen} size={18} />
            </Pressable>
          </View>
        </View>
      </View>
    );
  };

  const renderRecord = (record: Record, index: number): React.ReactElement => {
    let checked = selection.some(s => s === record.id);

    return (
      <Pressable
        key={index}
        style={({ pressed }) => [
          ReportsStyleSheet.listItemContainer,
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={() => select(record)}>
        <Text
          style={[ReportsStyleSheet.listItemName, { paddingLeft: 12 }]}
          numberOfLines={1}>
          {record.name}
        </Text>
        <View style={ReportsStyleSheet.listItemDateContent}>
          <Text style={ReportsStyleSheet.listItemDate}>
            {moment(record.datetime).toDate().toLocaleDateString('en-us', {
              day: '2-digit',
              month: 'short',
            })}
          </Text>
          <Text style={ReportsStyleSheet.listItemTime}>
            {moment(record.datetime).toDate().toLocaleTimeString('en-us', {
              hourCycle: 'h24',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Text>
        </View>
        <View
          style={[
            ControlsStyleSheet.checkBox,
            { marginHorizontal: 12 },
            checked && {
              backgroundColor: Colors.teal,
              borderColor: Colors.teal,
            },
          ]}>
          <Icon icon={'checked'} color={Colors.white} size={16} />
        </View>
      </Pressable>
    );
  };

  const renderSelectedRecords = (): React.ReactElement => {
    let selectedRecords = records.filter(s => value.includes(s.id));

    return (
      <View style={{ marginBottom: 24 }}>
        {selectedRecords.map((record, index) => {
          return renderSelectedRecord(record, index);
        })}
      </View>
    );
  };

  const renderSelectedRecord = (
    record: Record,
    index: number,
  ): React.ReactElement => {
    return (
      <Pressable
        key={index}
        style={({ pressed }) => [
          ReportsStyleSheet.listItemContainer,
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={() => goToRecord(record)}>
        <Text
          style={[ReportsStyleSheet.listItemName, { paddingLeft: 12 }]}
          numberOfLines={1}>
          {record.name}
        </Text>
        <View style={ReportsStyleSheet.listItemDateContent}>
          <Text style={ReportsStyleSheet.listItemDate}>
            {moment(record.datetime).toDate().toLocaleDateString('en-us', {
              day: '2-digit',
              month: 'short',
            })}
          </Text>
          <Text style={ReportsStyleSheet.listItemTime}>
            {moment(record.datetime).toDate().toLocaleTimeString('en-us', {
              hourCycle: 'h24',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Text>
        </View>
        <Icon icon={'list-item-nav'} color={Colors.darkestGreen} size={24} />
      </Pressable>
    );
  };

  return (
    <View
      style={{
        display:
          props.visible === false ||
          (props.config?.disabled && value.length === 0)
            ? 'none'
            : 'flex',
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginVertical: 24,
        }}>
        <Text style={ControlsStyleSheet.labelLarge}>
          {props.label ?? 'Link a record'}
        </Text>
        <Pressable
          style={({ pressed }) => [
            ControlsStyleSheet.linkRecordAddButton,
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
            //props.disabled means the DynamicForm is in read only mode
            //props.config?.disabled means the component is read only
            (props.disabled || props.config?.disabled) && { display: 'none' },
          ]}
          disabled={props.disabled}
          onPress={showModal}>
          <Icon icon={'plus'} color={Colors.darkestGreen} size={24} />
        </Pressable>
      </View>
      {renderSelectedRecords()}
      {renderModal()}
    </View>
  );
};

export default DynamicLinkRecord;
