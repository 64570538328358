import React, { useState, useEffect } from 'react';
import { View, Text, TextStyle, Pressable, ViewStyle } from 'react-native';
import { DynamicSegmentProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';

const DynamicSegmentedControl = (
  props: DynamicSegmentProps,
): React.ReactElement => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    if (props.value != value) setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!props.value && !value && props.defaultValue && props.onChange)
      props.onChange(
        props.controlId,
        props.controlTypeId,
        props.defaultValue,
        valid(),
      );
  }, [props.defaultValue]);

  useEffect(() => {
    if (value && value != props.value) {
      if (props.onChange)
        props.onChange(props.controlId, props.controlTypeId, value, valid());

      if (
        props.updateMetaData &&
        props.config?.hasMetaData &&
        props.config?.metaDataKey
      )
        props.updateMetaData(props.config.metaDataKey, value);
    }
  }, [value]);

  const valid = () => {
    return !(props.required && value === '');
  };

  const renderSegments = () => {
    let segments: React.ReactElement[] = [];

    for (let i = 0; i < props.config?.segments.length!; i++) {
      let segmentClass: ViewStyle = ControlsStyleSheet.segment;
      let segmentBackgroundStyle: ViewStyle = {
        backgroundColor: Colors.white,
      };
      let textColorStyle: TextStyle = { color: Colors.green };

      if (i == 0)
        segmentClass = {
          ...ControlsStyleSheet.segment,
          ...ControlsStyleSheet.segmentLeft,
        };
      else if (i == props.config?.segments.length! - 1)
        segmentClass = {
          ...ControlsStyleSheet.segment,
          ...ControlsStyleSheet.segmentRight,
        };

      let segmentName = props.config?.segments[i].name;
      let segmentValue = props.config?.segments[i].value ?? segmentName;
      let textClass: TextStyle = ControlsStyleSheet.segmentText;

      //Change style for selected segment
      if (segmentValue === value) {
        segmentBackgroundStyle.backgroundColor = Colors.green;
        textColorStyle.color = Colors.white;
      }

      segments.push(
        <Pressable
          key={i}
          style={({ pressed }) => [
            segmentClass,
            segmentBackgroundStyle,
            { minWidth: 60 },
            (pressed || props.disabled) && {
              opacity: 0.4,
            },
          ]}
          onPress={() => {
            setValue(segmentValue!);
          }}
          disabled={props.disabled}>
          <Text style={[textClass, textColorStyle]}>{segmentName}</Text>
        </Pressable>,
      );
    }

    return segments;
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text style={ControlsStyleSheet.label}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.required ? '*' : ''}
        </Text>
      </Text>
      <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
        {renderSegments()}
      </View>
    </View>
  );
};

export default DynamicSegmentedControl;
