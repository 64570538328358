import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Text,
  Image,
  ImageBackground,
  Pressable,
  Modal,
  ActivityIndicator,
} from 'react-native';
import Icon from '../Shared/Icon';
import {
  DynamicAttachment,
  DynamicAttachmentsProps,
} from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
/* import AttachmentService from '../../Services/AttachmentService'; */
/* import {IAttachmentService} from '../../Services/Interfaces/IAttachmentService'; */
import { Images } from '../../Constants/Images';
import AttachmentService from '../../Services/AttachmentService';
import { IAttachmentService } from '../../Services/Interfaces/IAttachmentService';
import { useSync } from '../../Providers/SyncProvider';
import { useCustomRealm } from '../../Providers/CustomRealmProvider';
import axios, { AxiosError } from 'axios';
import DismissModal from '../Shared/DismissModal';

type UriSasPair = {
  uri: string;
  sasUri: string;
};

const DynamicAttachments = (
  props: DynamicAttachmentsProps,
): React.ReactElement => {
  const { realmSignOut } = useCustomRealm();
  const [mode] = useState(props.config?.mode ?? 'attachments');
  const [maxCount, setMaxCount] = useState(0);
  const [maxSize, setMaxSize] = useState(0);
  const [attachments, setAttachments] = useState<DynamicAttachment[]>([]);
  const [error, setError] = useState('');
  const [previewPhoto, setPreviewPhoto] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [sasUris, setSasUris] = useState<UriSasPair[]>([]);

  const [isShowingQuestionModal, setIsShowingQuestionModal] = useState(false);

  const attachmentService: IAttachmentService = new AttachmentService(() => {
    realmSignOut();
  });

  const isMounted = useRef(false);

  const inputRef = React.createRef<HTMLInputElement>();

  const imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];

  useEffect(() => {
    if (props.config?.maxAttachments) setMaxCount(props.config?.maxAttachments);
    else if (process.env.REACT_APP_MAX_ATTACHMENTS_COUNT)
      setMaxCount(parseInt(process.env.REACT_APP_MAX_ATTACHMENTS_COUNT));

    if (props.config?.maxFileSizeMB) setMaxSize(props.config?.maxFileSizeMB);
    else if (process.env.REACT_APP_MAX_ATTACHMENTS_SIZE_MB)
      setMaxSize(parseInt(process.env.REACT_APP_MAX_ATTACHMENTS_SIZE_MB));

    if (props.value) {
      let parsedAttachments = JSON.parse(props.value!) as DynamicAttachment[];
      setAttachments(parsedAttachments);

      const getSasUri = async () => {
        let newSasUris: UriSasPair[] = [];
        for (let i = 0; i < parsedAttachments.length; i++) {
          try {
            let fullUri = await attachmentService.getSasUri(
              parsedAttachments[i].blobUri!,
            );

            newSasUris.push({
              uri: parsedAttachments[i].blobUri!,
              sasUri: fullUri,
            });

            if (parsedAttachments[i].blobThumbnailUri) {
              let thumbnailUri = await attachmentService.getSasUri(
                parsedAttachments[i].blobThumbnailUri!,
              );

              newSasUris.push({
                uri: parsedAttachments[i].blobThumbnailUri!,
                sasUri: thumbnailUri,
              });
            }
          } catch (err) {
            //failed getting sasuri
          }
        }

        setSasUris(newSasUris);
      };

      getSasUri();
    }

    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError, attachments]);

  function getValueFromProps(
    propsValue: string | undefined,
  ): Array<DynamicAttachment> {
    let newAttachments = new Array<DynamicAttachment>();

    if (propsValue) {
      try {
        let newValueArray = JSON.parse(propsValue) as Array<DynamicAttachment>;
        newAttachments = newValueArray;
      } catch (error) {
        /* TODO: Log errors. */
        console.log('Error: Bad Attachment serialization');
      }
    }

    return newAttachments;
  }

  function getFileName(attachment: DynamicAttachment): string {
    let fileName = attachment.name ?? '';
    if (fileName) {
      let extPosition = fileName.lastIndexOf('.');
      if (extPosition != -1) {
        let namePart = fileName.substring(0, extPosition);
        if (namePart.length > 24) namePart = namePart.substring(0, 26) + '..';

        fileName =
          namePart + '.' + fileName.substring(extPosition + 1, fileName.length);
      }
    }

    return fileName;
  }

  function viewPhoto(index: number): void {
    let photo = attachments[index];

    if (!photo || !photo.blobUri) return;

    if (photo) {
      setPreviewPhoto(photo.blobUri!);
      setShowModal(true);
    }
  }

  /*TODO will need to cache these attachments sas urls so client doesnt have to keep generating new sas urls. Need to keep track of sas expiries too
  
  const getBlobExpirationTime = (blobSasUrl: string) => {
    const blobFullUrl = new URLSearchParams(blobSasUrl);
    return blobFullUrl.get("se") || ""; //se = signature expiry
  };

  const updateBlobSasToken = async (blobUrl: string) => {
    props.setIsLoading(true);
    let sasUrl = await attachmentService.getSasUri(blobUrl);

    let newSasUris = [...sasUris];

    let sasUriToUpdate = newSasUris.find((x) => x.uri === blobUrl);
    sasUriToUpdate!.sasUri = sasUrl;

    setSasUris(newSasUris);

    props.setIsLoading(false);
    return sasUrl;
  };
*/

  async function showFilePicker(): Promise<void> {
    if (attachments.length >= maxCount) {
      setError('You have reached the maximum number of attachments');
      return;
    } else setError('');

    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  }

  async function selectFiles(files: FileList | null): Promise<void> {
    if (files) {
      try {
        // TODO: Implement attachments service
        // let uris = await attachmentService.addAttachments(
        //   props.submissionId,
        //   props.controlId,
        //   props.config?.allowedTypes!,
        // );
        props.setIsLoading(true);

        let maxByteSize = maxSize * 1024 * 1024;
        for (let i = 0; i < files.length; i++) {
          if (files[i].size >= maxByteSize) {
            props.setIsLoading(false);
            setError('File size must be less than ' + maxSize + 'MB');
            return;
          }
        }

        let newAttachments = [...attachments];
        let newSasUris = [...sasUris];
        for (let i = 0; i < files.length; i++) {
          let fileType = files[i].type;
          let fileName = files[i].name.replaceAll(' ', '_');
          if (newAttachments.length >= maxCount) {
            props.setIsLoading(false);
            setError('You have reached the maximum number of attachments');
            break;
          }

          if (!attachments.some(a => a.name === fileName)) {
            let uris: string[] = [];

            try {
              let fullImageUri = await attachmentService.pushAttachmentChunks(
                props.submissionId,
                props.attachmentsType,
                fileName,
                files[i],
              );
              uris.push(fullImageUri);

              if (files[i].type.includes('image/')) {
                let thumbnailFile = await attachmentService.createThumbnail(
                  files[i],
                );

                let thumbnailUri = await attachmentService.pushAttachmentChunks(
                  props.submissionId,
                  props.attachmentsType,
                  thumbnailFile.name,
                  thumbnailFile,
                );

                uris.push(thumbnailUri);
              }
            } catch (err: any | AxiosError) {
              props.setIsLoading(false);

              if (axios.isAxiosError(err)) {
                if (err.response?.status === 413) {
                  setError('File exceeds size limits.');
                  return;
                }
              }

              setError('Please try re-uploading your files');
              return;
            }

            if (
              (files[i].type === 'image/heic' ||
                files[i].type === 'image/heif') &&
              uris[0].match(/.+\.png$/)
            ) {
              //Rename type/extension if heic/heif file was converted to png
              fileType = 'image/png';
              fileName = fileName.split('.')[0] + '.png';
            }

            if (files[i].type.includes('image/')) {
              //uri[0] is thumbmail, uri[1] is full file
              //Something wrong upload failed
              if (uris.length != 2) {
                props.setIsLoading(false);
                setError('Please try re-uploading your files');
                return;
              }

              newAttachments.push({
                refId: props.submissionId,
                refType: props.attachmentsType,
                controlId: props.controlId,
                uri: '',
                name: fileName,
                type: fileType,
                size: files[i].size,
                blobUri: uris[0],
                blobThumbnailUri: uris[1],
              });

              let blobUri = await attachmentService.getSasUri(uris[0]!);
              let thumbnailUri = await attachmentService.getSasUri(uris[1]!);

              newSasUris.push({
                uri: uris[0],
                sasUri: blobUri,
              });

              newSasUris.push({
                uri: uris[1],
                sasUri: thumbnailUri,
              });
            } else {
              newAttachments.push({
                refId: props.submissionId,
                refType: props.attachmentsType,
                controlId: props.controlId,
                uri: '',
                name: fileName,
                type: files[i].type,
                size: files[i].size,
                blobThumbnailUri: null,
                blobUri: uris[0],
              });
            }
          }
        }

        setAttachments(newAttachments);
        let newValue = JSON.stringify([...newAttachments]);

        setSasUris(newSasUris);

        props.onChange!(
          props.controlId,
          props.controlTypeId,
          newValue,
          valid(),
        );
        props.setIsLoading(false);
      } catch (error) {
        /* TODO: Log errors. */
        props.setIsLoading(false);
        console.log(error);
      }
    }
  }

  async function removeAttachment(index: number): Promise<void> {
    let newAttachments = attachments;
    let deletedAttachments = newAttachments.splice(index, 1);
    setAttachments([...newAttachments]);

    if (deletedAttachments) {
      if (deletedAttachments[0].blobUri)
        await attachmentService.deleteBlobAttachment(
          deletedAttachments[0].blobUri,
        );

      if (deletedAttachments[0].blobThumbnailUri)
        await attachmentService.deleteBlobAttachment(
          deletedAttachments[0].blobThumbnailUri,
        );
    }

    let newValue = JSON.stringify(newAttachments);
    props.onChange!(props.controlId, props.controlTypeId, newValue, valid());
  }

  // TODO: Implement attachments service
  async function downloadAttachment(index: number): Promise<void> {
    // let photo = attachments[index];
    // if (photo) {
    //   // Copy file to Downloads folder
    //   let path = RNFS.DownloadDirectoryPath + '/' + photo.name;
    //   await copyFile(photo.uri, path);
    //   //Open file
    //   await FileViewer.open(path, {showOpenWithDialog: true});
    // }
    let attachment = attachments[index];

    if (attachment && attachment.blobUri && attachment.name)
      await attachmentService.downloadAttachment(
        attachment.blobUri,
        attachment.name,
      );
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (
      props.config?.required &&
      isMounted.current &&
      attachments.length === 0
    ) {
      isValid = false;
      error =
        mode === 'photos'
          ? 'You need to add photos'
          : 'You need to add attachments';
    }

    if (props.showError) setError(error);

    return isValid;
  }

  const renderPhoto = (
    attachment: DynamicAttachment,
    index: number,
  ): React.ReactElement => {
    let sasUri = sasUris.find(x => x.uri == attachment.blobThumbnailUri);
    let uri = '';

    if (sasUri) uri = sasUri.sasUri;

    let fileName = getFileName(attachment);

    return (
      <ImageBackground
        key={index}
        source={{ uri: uri }}
        style={ControlsStyleSheet.attachmentsItem}>
        {uri ? (
          <View style={ControlsStyleSheet.attachmentsActions}>
            <Pressable
              style={({ pressed }) => [
                ControlsStyleSheet.attachmentsAction,
                pressed && {
                  backgroundColor: Colors.lightTeal,
                },
              ]}
              onPress={() => viewPhoto(index)}>
              <Icon icon={'eye'} color={Colors.green} size={20} />
            </Pressable>
            {!props.disabled && (
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.attachmentsAction,
                  pressed && {
                    backgroundColor: Colors.lightTeal,
                  },
                ]}
                onPress={() => removeAttachment(index)}>
                <Icon icon={'trash'} color={Colors.green} size={20} />
              </Pressable>
            )}
            {props.disabled && (
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.attachmentsAction,
                  pressed && {
                    backgroundColor: Colors.lightTeal,
                  },
                ]}
                onPress={() => downloadAttachment(index)}>
                <Icon icon={'download'} color={Colors.green} size={20} />
              </Pressable>
            )}
          </View>
        ) : (
          <>
            <ActivityIndicator color={Colors.green} size={32} />
            <Text style={ControlsStyleSheet.attachmentsText}>{fileName}</Text>
          </>
        )}
      </ImageBackground>
    );
  };

  const renderAttachment = (
    attachment: DynamicAttachment,
    index: number,
  ): React.ReactElement => {
    let fileName = getFileName(attachment);

    return (
      <View key={index} style={ControlsStyleSheet.attachmentsItem}>
        {attachment.type === 'application/pdf' ? (
          <Image style={{ width: 32, height: 32 }} source={Images.PDF} />
        ) : (
          <Icon icon={'file'} color={Colors.green} size={20} />
        )}
        <Text style={ControlsStyleSheet.attachmentsText}>{fileName}</Text>
        <View style={ControlsStyleSheet.attachmentsActions}>
          {!props.disabled && (
            <Pressable
              style={({ pressed }) => [
                ControlsStyleSheet.attachmentsAction,
                pressed && {
                  backgroundColor: Colors.lightTeal,
                },
              ]}
              onPress={() => removeAttachment(index)}>
              <Icon icon={'trash'} color={Colors.green} size={20} />
            </Pressable>
          )}
          {props.disabled && (
            <Pressable
              style={({ pressed }) => [
                ControlsStyleSheet.attachmentsAction,
                pressed && {
                  backgroundColor: Colors.lightTeal,
                },
              ]}
              onPress={() => downloadAttachment(index)}>
              <Icon icon={'download'} color={Colors.green} size={20} />
            </Pressable>
          )}
        </View>
      </View>
    );
  };

  const renderNotSynced = (
    attachment: DynamicAttachment,
    index: number,
  ): React.ReactElement => {
    let fileName = getFileName(attachment);

    return (
      <View key={index} style={ControlsStyleSheet.attachmentsItem}>
        <Icon icon={'syncing'} color={Colors.green} size={20} />
        <Text style={ControlsStyleSheet.attachmentsText}>{fileName}</Text>
        <Text
          style={[
            ControlsStyleSheet.attachmentsText,
            { fontSize: 10, marginTop: 4, textTransform: 'none' },
          ]}>
          This file has not been synced yet.
        </Text>
        <View
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}>
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.attachmentsAction,
              { borderColor: Colors.green, borderWidth: 2 },
              pressed && {
                backgroundColor: Colors.lightTeal,
              },
            ]}
            onPress={() => setIsShowingQuestionModal(true)}>
            <Icon icon={'question'} color={Colors.green} size={12} />
          </Pressable>
        </View>
        <View style={ControlsStyleSheet.attachmentsActions}>
          {!props.disabled && (
            <Pressable
              style={({ pressed }) => [
                ControlsStyleSheet.attachmentsAction,
                pressed && {
                  backgroundColor: Colors.lightTeal,
                },
              ]}
              onPress={() => removeAttachment(index)}>
              <Icon icon={'trash'} color={Colors.green} size={20} />
            </Pressable>
          )}
        </View>
      </View>
    );
  };

  const renderPreviewPhoto = (): React.ReactElement => {
    let sasUri = sasUris.find(x => x.uri == previewPhoto);
    let uri = '';

    if (sasUri) uri = sasUri.sasUri;

    return (
      <Modal
        visible={showModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => {
                  setPreviewPhoto('');
                  setShowModal(false);
                }}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                marginVertical: 24,
              }}>
              <Image
                resizeMode="contain"
                style={{ width: '100%', height: '100%' }}
                source={{ uri: uri }}
              />
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <View style={ControlsStyleSheet.attachmentsContainer}>
        {!props.disabled && (
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.attachmentsItem,
              pressed && {
                opacity: 0.6,
              },
            ]}
            onPress={showFilePicker}>
            <Icon icon={'file'} color={Colors.green} size={24} />
            <Text style={ControlsStyleSheet.attachmentsText}>
              Add an attachment
            </Text>
          </Pressable>
        )}
        {attachments.map((attachment, index) => {
          if (!attachment.blobUri) return renderNotSynced(attachment, index);
          else if (attachment.type && imageTypes.includes(attachment.type))
            return renderPhoto(attachment, index);
          else return renderAttachment(attachment, index);
        })}
      </View>
      {props.disabled && attachments.length === 0 && (
        <Text style={ControlsStyleSheet.label}>
          There are no attached files
        </Text>
      )}
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      <input
        ref={inputRef}
        type="file"
        multiple
        style={{ display: 'none' }}
        accept={props.config?.allowedTypes?.join(',')}
        onChange={event => selectFiles(event.target.files)}
      />
      {renderPreviewPhoto()}
      <DismissModal
        isShowingModal={isShowingQuestionModal}
        title="What is this?"
        text="This file was added from a mobile device and has not been synchronized yet. It will be available once the user's device completes synchronization."
        onDismiss={() => setIsShowingQuestionModal(false)}
      />
    </View>
  );
};

export default DynamicAttachments;
