import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { DynamicAttachmentType } from '../Types/ControlTypes';
import Config from 'react-native-config';
import { IApiService } from './Interfaces/IApiService';
import ApiService from './ApiService';
import LocalStorageService from '../Services/LocalStorageService';
import { ILocalStorageService } from '../Services/Interfaces/ILocalStorageService';
import { STORAGE_KEYS } from '../Constants/AppConstants';
import { Formats } from '../Constants/Formats';
import Resizer from 'react-image-file-resizer';

class AttachmentService {
  apiService: IApiService;
  storageService: ILocalStorageService;

  constructor(realmSignout: () => void) {
    this.apiService = new ApiService(realmSignout);
    this.storageService = new LocalStorageService();
  }

  async pushAttachmentChunks(
    refId: string,
    refType: DynamicAttachmentType,
    fileName: string,
    file: File,
  ): Promise<string> {
    let url: string | undefined = '';
    url = process.env.REACT_APP_UPLOAD_ATTACHMENT_CHUNK_URL;

    const oneMBInBytes = 1 * 1024 * 1024;

    let result = await this.uploadFileInChunks(
      file,
      refId,
      refType,
      fileName,
      oneMBInBytes,
      url!,
    );
    return result;
  }

  //Deprecated
  async pushAttachment(
    refId: string,
    refType: DynamicAttachmentType,
    fileName: string,
    file: File,
  ): Promise<string[]> {
    let formData = new FormData();
    formData.append('FileName', fileName);
    formData.append('File', file);
    formData.append('ContentType', file.type);

    let url: string | undefined = '';
    if (
      refType === 'submission' ||
      refType === 'submissiontraining' ||
      refType === 'submission-bundle'
    ) {
      formData.append('SubmissionId', refId);
      url = process.env.REACT_APP_UPLOAD_ATTACHMENT_URL;
    } else if (
      refType === 'equipment-vehicle-profile' ||
      refType === 'equipment-heavy-profile' ||
      refType === 'equipment-other-profile'
    ) {
      formData.append('ProfileId', refId);
      formData.append('ProfileType', 'EquipmentProfile');
      url = process.env.REACT_APP_UPLOAD_PROFILE_URL;
    } else if (refType === 'person-profile') {
      formData.append('ProfileId', refId);
      formData.append('ProfileType', 'PersonProfile');
      url = process.env.REACT_APP_UPLOAD_PROFILE_URL;
    } else if (refType === 'company-document')
      url = process.env.REACT_APP_UPLOAD_COMPANY_DOC_URL;

    let config: AxiosRequestConfig = {};

    config.data = formData;
    config.method = 'POST';
    config.url = url;
    config.headers = {
      'Content-Type': 'multipart/form-data',
    };

    let reponse = await this.apiService.sendRequest<any>(config);
    return reponse.data;
  }

  async downloadAttachment(url: string, fileName: string) {
    let sasUrl = await this.getSasUri(url);

    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob'; //Set the response type to blob so xhr.response returns a blob
    xhr.open('GET', sasUrl, true);

    xhr.onreadystatechange = function () {
      if (xhr.readyState == xhr.DONE) {
        var blob = new Blob([xhr.response]);

        var element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = fileName;
        element.click();
      }
    };

    xhr.send(); //Request is sent
  }

  //Delete attachment on azure blob
  async deleteBlobAttachment(uri: string): Promise<boolean> {
    try {
      let url = process.env.REACT_APP_DELETE_BLOB_URL + '?blobUri=' + uri;
      let config: AxiosRequestConfig = {};

      config.method = 'POST';
      config.url = url;

      let reponse = await this.apiService.sendRequest<any>(config);
      if (reponse.status == 200) return true;
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async getSasUri(uri: string): Promise<string> {
    let localSasUri = this.getLocalSasUri(uri);
    if (localSasUri) return localSasUri;

    let sasUrl = process.env.REACT_APP_GET_ATTACHMENT_SAS_URL;
    let formData = new FormData();

    formData.append('uri', uri);

    let config: AxiosRequestConfig = {};
    config.data = { uri: uri };
    config.method = 'POST';
    config.url = sasUrl;
    config.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

    let getSasUriPromise = new Promise<string>((resolve, reject) => {
      this.apiService
        .sendRequest<any>(config)
        .then(
          (response: any) => {
            this.saveLocalSasUri(uri, response.data);
            resolve(response.data);
          },
          error => {
            reject(error);
          },
        )
        .catch(function (error) {
          reject(error);
        });
    });

    return getSasUriPromise;
  }

  saveLocalSasUri(uri: string, sasUri: string) {
    let sasUrisJson = this.storageService.get(
      STORAGE_KEYS.ATTACHMENTS_SAS_URIS,
    );
    let sasUrisObjs: {
      uri: string;
      sasUri: string;
      expiration: string;
    }[] = [];

    if (sasUrisJson) {
      try {
        sasUrisObjs = JSON.parse(sasUrisJson) as {
          uri: string;
          sasUri: string;
          expiration: string;
        }[];
        let sasUriObj = sasUrisObjs.find(s => s.uri === uri);

        let expiration = '';
        let decodedUrl = decodeURI(sasUri);
        let urlParts = decodedUrl.split('?');
        if (urlParts.length === 2) {
          let urlParams = urlParts[1];
          let params = new URLSearchParams(urlParams);
          let se = params.get('se');
          if (se) expiration = se;
        }

        if (sasUriObj) {
          sasUriObj.sasUri = sasUri;
          sasUriObj.expiration = expiration;
        } else
          sasUrisObjs.push({
            uri: uri,
            sasUri: sasUri,
            expiration: expiration,
          });
      } catch (error) {
        console.log('Error: Bad SasUri serialization');
      }
    }

    this.storageService.set(
      STORAGE_KEYS.ATTACHMENTS_SAS_URIS,
      JSON.stringify(sasUrisObjs),
    );
  }

  getLocalSasUri(uri: string): string {
    let localSasUri = '';

    let sasUrisJson = this.storageService.get(
      STORAGE_KEYS.ATTACHMENTS_SAS_URIS,
    );

    if (sasUrisJson) {
      try {
        let sasUrisObjs = JSON.parse(sasUrisJson) as {
          uri: string;
          sasUri: string;
          expiration: string;
        }[];

        let sasUriObj = sasUrisObjs.find(s => s.uri === uri);
        let now = moment().toDate();
        if (
          sasUriObj &&
          moment(sasUriObj.expiration, Formats.BACKEND_DATE).toDate() > now
        )
          localSasUri = sasUriObj.sasUri;
      } catch (error) {
        console.log('Error: Bad SasUri serialization');
      }
    }

    return localSasUri;
  }

  async uploadFileInChunks(
    file: File,
    fileId: string,
    fileType: string,
    fileName: string,
    chunkSizeInBytes: number,
    uploadChunkUrl: string,
  ) {
    let blockIds: string[] = [];
    let fileChunks: Blob[] = [];

    let currentPosition = 0;
    let endPosition = chunkSizeInBytes;
    let size = file.size;
    let fileContentType = file.type;

    while (currentPosition < size) {
      fileChunks.push(file.slice(currentPosition, endPosition));
      currentPosition = endPosition;
      endPosition = currentPosition + chunkSizeInBytes;
    }

    let result = await this.uploadChunk(
      fileId,
      fileType,
      fileContentType,
      fileName ? fileName : file.name,
      fileChunks,
      1,
      fileChunks.length,
      blockIds,
      uploadChunkUrl,
    );

    return result;
  }

  async uploadChunk(
    fileId: string,
    fileType: string,
    fileContentType: string,
    fileName: string,
    fileChunks: Blob[],
    current: number,
    total: number,
    blockIds: string[],
    uploadChunkUrl: string,
  ): Promise<string> {
    let self = this;

    async function uploadChunkSuccess(response: any) {
      if (response.data.status === 200) {
        if (current == total) {
          return response.data.result;
        } else {
          blockIds.push(response.data.result);
          return await self.uploadChunk(
            fileId,
            fileType,
            fileContentType,
            fileName,
            fileChunks,
            current + 1,
            total,
            blockIds,
            uploadChunkUrl,
          );
        }
      } else {
        throw new Error('Error uploading file');
      }
    }

    let payload = new FormData();
    payload.append('File', fileChunks[current - 1], fileName);
    payload.append('FileId', fileId);
    payload.append('FileType', fileType);
    payload.append('ContentType', fileContentType);
    payload.append('ChunkNumber', current.toString());
    payload.append('TotalChunks', total.toString());
    payload.append('BlockIds', JSON.stringify(blockIds));

    payload.append('FileName', fileName);

    let config: AxiosRequestConfig = {};
    let url: string | undefined =
      process.env.REACT_APP_UPLOAD_ATTACHMENT_CHUNK_URL;

    config.data = payload;
    config.method = 'POST';
    config.url = url;
    config.headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      let response = await this.apiService.sendRequest<any>(config);
      return await uploadChunkSuccess(response);
    } catch (err: any) {
      throw new Error('Error upload file');
    }
  }

  createThumbnail(file: File) {
    let fileTokens = file.name.split('.');
    let newName =
      fileTokens.splice(0, fileTokens.length - 1).join('.') +
      '_tn.' +
      fileTokens[fileTokens.length - 1];

    return new Promise<File>(resolve => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        'PNG',
        100,
        0,
        file => {
          let thumbnailFile = file as File;
          let blob = thumbnailFile.slice(0, thumbnailFile.size);
          let newFile = new File([blob], newName, { type: thumbnailFile.type });
          resolve(newFile);
        },
        'file',
      );
    });
  }
}

export default AttachmentService;
